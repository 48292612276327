import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  ImageCollection,
  ContactForm,
} from "@bluefin/components";
import { Grid, h5, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <h1>Catering</h1>
              <Grid stackable={true} textAlign={"center"} columns={3}>
                <Grid.Column>
                  <h5>Meats</h5>
                  <List
                    items={[
                      "BBQ Teriyaki Chicken (boneless skinless chicken)",
                      "Huli-Huli Chicken (rotisserie chicken leg quarters, bone-in)",
                      "Tri Tip",
                      "Kalua Pork",
                      "Pork Ribs",
                      "Pineapple Meatballs",
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <h5>Sides</h5>
                  <List
                    items={[
                      "Potato Salad",
                      "Macaroni Salad",
                      "Pineapple Coleslaw",
                      "Green Salad",
                      "Caesar Salad",
                      "Grilled Veggies",
                      "Fresh Fruit",
                      "Baked Beans",
                      "Petite Roasted Potatoes",
                      "Chop Suey",
                      "White Rice",
                    ]}
                  />
                </Grid.Column>
                <Grid.Column>
                  <h5>Appetizers and Extras</h5>
                  <List
                    items={[
                      "Crab Cheese Puffs",
                      "Beef Egg Rolls",
                      "Coconut Shrimp",
                      "Creamy Sausage Dip and Chips",
                      "Pineapple Sausage",
                      "Hot Links",
                    ]}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={[
                  "https://fisherman.gumlet.io/public/sammysbbqcatering/89845777_3055802257791965_8552651295163416576_n_1.jpg",
                  "https://fisherman.gumlet.io/public/sammysbbqcatering/IMG_0920.jpeg",
                  "https://fisherman.gumlet.io/public/sammysbbqcatering/IMG_3097.jpeg",
                ]}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column computer={12} mobile={16}>
              <p>
                Here’s a list of menu items we offer for catering. Catered
                orders are priced per person and depend on the menu items you
                have selected. Send us a message with the date, headcount, and
                the menu you have in mind and we’ll contact you within 1-2 days
                with a proposal.
              </p>
              <ContactForm
                buttonText={"Submit"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Submit A Catering Request"}
                withContainer={true}
                termsCopy={""}
                subheader={""}
                showLabels={true}
                emailSubject={"Catering Request"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Catering" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
